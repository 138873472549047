import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LoadIndicatorService} from '@ratespecial/core';

@Injectable()
export class LoadIndicatorInterceptor implements HttpInterceptor {

  constructor(private loadIndicatorService: LoadIndicatorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const key = req.urlWithParams;

    this.loadIndicatorService.push(key);

    return next
      .handle(req)
      .pipe(
        tap(
          resp => {
            if (resp instanceof HttpResponse) {
              this.loadIndicatorService.pop(key);
            }
          },
          () => {
            this.loadIndicatorService.pop(key);
          })
      );
  }
}
