import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LoadIndicatorInterceptor} from './interceptor/http/loadindicator.interceptor';
import {AuthJwtInterceptor} from './interceptor/http/auth-jwt.interceptor';
import {LogoutOnUnauthInterceptor} from './interceptor/http/logout-on-unauth.interceptor';
import {ServerErrorInterceptor} from './interceptor/http/server-error.interceptor';
import {ErrorService} from './error/error.service';
import {AuthService} from './auth/services/auth.service';
import {HttpErrorService} from './http/http-error.service';
import {ActiveModalRegistryFactory} from './modal/active-modal-registry.factory';
import {provideNgxWebstorage, withLocalStorage, withSessionStorage} from 'ngx-webstorage';
import {HistoryService} from './auth/services/history.service';
import {TokenStoreService} from './auth/services/token-store.service';
import {AuthGuard} from './auth/services/auth.guard';
import {LoginGuard} from './auth/services/login.guard';
import {ConfirmationModalComponent} from './modal/confirmation-modal/confirmation-modal.component';
import {RsCoreModule} from '@ratespecial/core';

@NgModule({
  declarations: [
    ConfirmationModalComponent,
  ],
  exports: [
    ConfirmationModalComponent,
    FormsModule,
    NgbModule,
    NgbModalModule,
    RsCoreModule,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgbModalModule,
    RsCoreModule
  ],
  providers: [
    ActiveModalRegistryFactory,
    AuthService,
    ErrorService,
    HistoryService,
    HttpErrorService,
    AuthGuard,
    LoginGuard,
    TokenStoreService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadIndicatorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthJwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogoutOnUnauthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxWebstorage(
      withLocalStorage(),
      withSessionStorage(),
    ),
  ],
})
export class SharedModule {

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [ErrorService, ActiveModalRegistryFactory],
    };
  }

  static forChild(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
