<!-- Errors -->
<div *ngIf="errorService.errors">
  <div class="alert alert-danger" role="alert" *ngFor="let key of objectKeys(errorService.errors)">
    {{errorService.errors[key]}}
  </div>
</div>

<div *ngIf="errorService.apiErrors.length > 0">
  <div class="alert alert-danger" role="alert" *ngFor="let error of errorService.apiErrors">
    <strong>{{error.code}} {{error.title}}</strong> {{error.detail}}
  </div>
</div>

<!--<pre>{{errorService.errors | json}}</pre>-->
<!--<pre>{{errorService.apiErrors | json}}</pre>-->