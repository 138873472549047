import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {HttpErrorService} from '../../http/http-error.service';
import {AlertService} from "@ratespecial/core";


/**
 * If any ajax call results in an error, we handle here to avoid duplicate code in the various components
 * and services that make requests.  422 Errors are not caught to allow Form Validation Errors to be handled
 * by the component directly
 */
@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

    constructor(
        private alertService: AlertService,
        private httpErrorService: HttpErrorService
    ) {

    }


    /**
     * Handle all general Http application errors except for form validation and authentication
     *
     * Note: Most RS functionality is standardized to our Api Error object.  There are some edge cases with
     * laravel and third party libs that may not be trapped and converted to Api Errors by the Laravel global
     * error handler and thus come back in another form.  I'm fixing those as they surface  - Joe
     *
     * @param {HttpRequest<any>} req
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next
            .handle(req)
            .pipe(
                tap(
                    () => {
                    },
                    err => {
                        //Allow 422 pass through to the component's form validation handler because use cases vary for forms
                        // and modal windows that need a different error presentation than the typical alert logic.
                        //Allow 401 to be handled by auth interceptor
                        if (err instanceof HttpErrorResponse && err.status !== 422
                            && err instanceof HttpErrorResponse && err.status !== 401
                        ){
                            this.httpErrorService.handleError(err);

                        } else if (err.error instanceof Error) {
                            this.alertService.error("A Network Error Occurred");
                        }
                    }
                )
            );
    }
}
