import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "./auth.service";
import {Observable} from 'rxjs';
import {HistoryService} from './history.service';

@Injectable()
export class AuthGuard {

  constructor(
    private authService: AuthService,
    private router: Router,
    private historyService: HistoryService,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!this.authService.isAuthenticated()) {
      this.historyService.setLastVisitedRoute(this.router.url);
      this.router.navigateByUrl('/auth/login');
    }

    return this.authService.isAuthenticated();
  }
}
