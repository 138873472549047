import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Observable} from 'rxjs';

import {TokenStoreService} from '../../auth/services/token-store.service';

@Injectable()
export class AuthJwtInterceptor implements HttpInterceptor {

  public tokenService: TokenStoreService;

  constructor(
      private injector: Injector
  ) {
    this.tokenService = this.injector.get(TokenStoreService);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //const tokenService = this.injector.get(TokenStoreService);
    const token = this.tokenService.getToken();

    if (!!token) {
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token)
      });
    }

    return next.handle(req);
  }
}
