import {Injectable} from '@angular/core';
import {isIterable} from 'rxjs/internal/util/isIterable';


@Injectable()
export class ErrorService {

  /**
   * API Errors
   * Follows our API Error standard of error objects
   */
  public apiErrors: Array<any> = [];

  /**
   * General Errors
   * For example, Laravel 422 form validation is just array of fieldname/validation error
   */
  public errors = {};


  constructor() {
  }

  /**
   * Add error (typically meant for Laravel form validation style of key value)
   * @param key
   * @param value
   */
  addError(key: string, value: Array<string>) {
    this.errors[key] = value;
  }


  addApiError(error) {
    this.apiErrors.push(error);
  }


  /**
   * Handle 422 Form Validation Errors
   * @param err
   */
  handleValidationErrors(err) {

    if (err.status == 422) {

      if (err.error.hasOwnProperty('errors')) {

        this.processErrors(err.error.errors);

      } else {
        this.errors = err.error;
      }
    }
  }


  processErrors(errors) {

    for (const fieldName in errors) {

      if (isIterable(errors[fieldName])) {
        this.addError(fieldName, errors[fieldName]);
      } else {
        this.addApiError(errors[fieldName]);
      }
    }
  }


  /**
   * Clear All Errors
   */
  clearErrors() {
    this.errors = {};
    this.apiErrors = [];
  }

}
