import {Component, OnInit} from '@angular/core';
import {ErrorService} from './error.service';
import {NgForOf, NgIf} from '@angular/common';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css'],
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
  ],
})
export class ErrorComponent implements OnInit {

  /**
   * Add reference to Object.keys function to template
   */
  public objectKeys = Object.keys;

  //Note: ErrorService is used directly by the template
  constructor(public errorService: ErrorService) { }

  ngOnInit() {

  }
}
