import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from "@angular/router";
import {TokenStoreService} from '../../auth/services/token-store.service';
import {ActiveModalRegistryFactory} from '../../modal/active-modal-registry.factory';
import {AuthService} from '../../auth/services/auth.service';
import {AlertService} from "@ratespecial/core";

/**
 * If any ajax call results in a 401, destroy our token and go to login.
 */
@Injectable()
export class LogoutOnUnauthInterceptor implements HttpInterceptor {

    /**
     * TODO: Move this to common event service so others can subscribe to close modals until they build something
     * into NgBootstrap Modals to dismiss any active modal
     *
     * @type {EventEmitter<boolean>}
     */
    //@Output() onLogoutEvent = new EventEmitter<any>();

    constructor(
        private tokenService: TokenStoreService,
        private modalFactory: ActiveModalRegistryFactory,
        private alertService: AlertService,
        private router: Router,
        private authService: AuthService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next
            .handle(req)
            .pipe(
                tap(
                    () => {
                    },
                    err => {
                        if(err instanceof HttpErrorResponse) {
                            if (err.status == 401 || (err.status == 400 && err.error.hasOwnProperty('error') && err.error.error === 'token_not_provided') ) {
                                //Close any open modals and logout
                                this.modalFactory.close();
                                this.authService.logout(true);
                            }
                        }
                    }
                )
            )
    }
}
