import {Injectable} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap/modal/modal-config';
import {AuthService} from '../auth/services/auth.service';

@Injectable({
    providedIn: 'root',
})
@Injectable()
export class ActiveModalRegistryFactory {

    /**
     * Reference to the currently active modal
     */
    private static activeModal: NgbModalRef;

    /**
     * Modals that have route bypass 'true' are modals that we can override the canDeactivate
     * when session expires
     * @type {boolean}
     */
    private static hasRouteBypass = false;

    constructor(
        private authService: AuthService,
        private modalService: NgbModal,
    ) {}

    /**
     * Wrapper around NgbModal.open so that we can register the active modal here in this class to provide
     * hooks to close it via interceptors
     *
     * @param content
     * @param {NgbModalOptions} options
     * @param {boolean} hasRouteBypass
     * @returns {NgbModalRef}
     */
    open(content: any, options?: NgbModalOptions, hasRouteBypass=false): NgbModalRef {

        // Check if authenticated before opening
        if(this.authService.isAuthenticated() === false) {
            this.authService.logout(true);
        }else{
            ActiveModalRegistryFactory.activeModal = this.modalService.open(content, options);
            ActiveModalRegistryFactory.hasRouteBypass = hasRouteBypass;
            return ActiveModalRegistryFactory.activeModal;
        }
    }

    /**
     * Close any open modals
     *
     * Note: This only works on modals that fetch data from the backend if tied to an interceptor
     */
    close() {
        if(ActiveModalRegistryFactory.activeModal) {
            ActiveModalRegistryFactory.activeModal.close();
            ActiveModalRegistryFactory.activeModal = null;
        }
    }

    hasRouteBypass() {
        return ActiveModalRegistryFactory.hasRouteBypass;
    }
}